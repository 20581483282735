import { createWebHistory, createRouter } from 'vue-router'
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants'

// import PageNotFound from '@/views/PageNotFound.vue'

import { bet } from './bet'
import { charge } from './charge'
import { exchange } from './exchange'
import { point } from './point'
import { coupon } from './coupon'
import { msg } from './msg'
import { cscenter } from '@/router_mobile/cscenter'
import { attendance } from './attendance'
import { winlose } from './winlose'
import { info } from './info'
import { stributor } from './stributor'
import { striNew } from './striNew'
import { result } from './result'
import { board } from './board'
import { gameLink } from './gameLink'
import { myinfo } from './myinfo'
import { give } from './give'
import { partner } from '@/router/partner'
import { gamelist } from './gamelist'
import { getLangCookie, tokenCheck } from '@/libs/auth-helper'

const memberRoutes = [
  bet,
  charge,
  exchange,
  point,
  coupon,
  msg,
  cscenter,
  attendance,
  winlose,
  info,
  stributor,
  striNew,
  result,
  board,
  gameLink,
  myinfo,
  give,
  partner
]
const routes = [
  {
    path: '/',
    name: 'mobileMain',
    component: () => import('@/views_mobile/mIndex'),
    meta: {
      layout: 'mobile-default',
      guestOnly: true
    }
  },
  gamelist,
  {
    path: '/login',
    name: 'mobileLogin',
    component: () => import('@/views_mobile/auth/login'),
    meta: {
      layout: 'mobile-empty',
      guestOnly: true
    }
  },
  {
    path: '/join',
    name: 'mobileJoin',
    component: () => import('@/views_mobile/auth/join'),
    meta: {
      layout: 'mobile-empty',
      guestOnly: true
    }
  }, /* {
    name: 'my',
    path: '/my',
    component: () => import('@/views_mobile/member/mypage'),
    redirect: {
      name: 'myinfo'
    },
    meta: {
      layout: 'mobile-empty',
      text: '마이페이지',
      gnb: false
    },
    children: [
      {
        name: 'myinfo',
        path: '/myinfo',
        component: () => import('@/views_mobile/member/mypage/myinfo'),
        meta: {
          layout: 'mobile-empty',
          text: '베팅리스트',
          gnb: false
        }
      }
    ]
  } */
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views_mobile/member'),
    children: memberRoutes
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'mobileMain'
    }
  }
]

const router = createRouter({
  history: createWebHistory(), // 2.x대를 설치하시면 작동을 안합니다.
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 0)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const title = DOCUMENT_TITLE_PREFIX
  const result = await tokenCheck()
  if (to.meta.guestOnly || result) {
    next()
  } else {
    const lang = getLangCookie()
    let msg = '로그인 이후 이용 가능합니다.'
    if (lang !== 'ko') {
      msg = 'Please Log-in'
    }
    alert(msg)
    location.href = '/'
  }

  document.title = title
})

export default router
