<template>
  <transition name="fade">
    <new-message-mobile v-if="isUnReadMessage" />
  </transition>

  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>

  <template v-if="type != 'empty'">
    <!-- <div class="fx">
      <ul class="fxhead">
        <li class="home" :class="{'on': $route.name === 'mobileMain'}"><a @click="goPageByName('mobileMain')"><em></em><span>홈</span></a></li>
        <li class="in" :class="{'on': $route.meta.gnbMenu === 'charge'}"><a @click="goPageByName('charge')"><em></em><span>{{$t('front.common.deposit')}}</span></a></li>
        <li class="outs" :class="{'on': $route.meta.gnbMenu === 'exchange'}"><a @click="goPageByName('exchange')"><em></em><span>{{$t('front.common.withdrawal')}}</span></a></li>
        <li class="point" :class="{'on': $route.meta.gnbMenu === 'point'}"><a @click="goPageByName('point')"><em></em><span>{{$t('front.common.point')}}</span></a></li>
        <li v-if="!userData" class="join" :class="{'on': $route.name === 'mobileJoin'}"><a @click="goPageByName('mobileJoin')"><em></em><span>회원가입</span></a></li>
        <li v-if="userData" class="logout"><a @click="signOut()"><em></em><span>{{$t('front.common.logout')}}</span></a></li>
      </ul>
    </div> -->
    <header>
      <div class="header">
        <span class="sideBtn" @click="side=!side"></span>
        <h1 class="logo" @click="goPageByName('mobileMain')"><img src="@/assets/img/site_logo.png" /></h1>
        <div class="logininfowrap" v-if="userData">
          <a class="mloginbtn" @click="signOut"><img src="../assets_mobile/img/logout-y.svg"></a>
        </div>
        <div class="logininfowrap" v-if="!userData">
          <a class="mloginbtn" @click="goPageByName('mobileLogin')"><img src="../assets/img/icon-lock.svg"></a>
        </div>
      </div>
      <!-- <div class="topbanner">
        <div class="topbannerin">
            <div class="speakerIcon"><img src="@/assets/img/icon_mega.svg"/></div>
            <span class="">{{$t('front.header.msg')}}</span>
        </div>
      </div> -->
    </header>
    <transition name="side">
       <nav v-if="side">
         <div class="nav-wrap">
            <div class="nav-head">
               <div class="header">
                 <h1 class="logo"><a @click="goPageByNamez('mobileMain')"><img src="@/assets/img/site_logo.png" /></a></h1>
               </div>
               <a class="sideMenu" @click="side=!side" :class="{'in': side}"><img src="@/assets/img/icon_x.svg" /></a>
            </div>
            <div class="nav-con">
              <div class="nav-info" v-if="userData">
                <button class="reBtn"><img src="../assets/img/icon/icon_refresh.svg"></button>
                <p>보유머니 : <em>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</em> 원</p>
                <p>포인트 : <em>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</em> P</p>
                <a :href="'/GL4'" target="_blank" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">파트너페이지</a>
              </div>
              <ul class="side-game">
                <li>
                  <a @click="goPageByNamez('listcasino')">
                    <img src="@/assets/img/left/icon_left_point.svg" />
                    <span>라이브카지노</span>
                  </a>
                </li>
                <!-- <li>
                  <a @click="goPageByNamez('listhotel')">
                    <img src="@/assets/img/left/icon_left_point.svg" />
                    <span>호텔카지노</span>
                  </a>
                </li> -->
                <li>
                  <a @click="goPageByNamez('listslot')">
                    <img src="@/assets/img/left/icon_left_point.svg" />
                    <span>슬롯게임</span>
                  </a>
                </li>
                <!-- <li>
                  <a @click="goPageByNamez('listmini')">
                    <img src="@/assets/img/left/icon_left_point.svg" />
                    <span>미니게임</span>
                  </a>
                </li> -->
                <!-- <li>
                  <a @click="goPageByNamez('listsports')">
                    <img src="@/assets/img/left/icon_left_point.svg" />
                    <span>스포츠</span>
                  </a>
                </li> -->
              </ul>
              <ul>
                <li>
                  <a @click="goPageByNamez('msg')">
                    <img src="@/assets/img/left/icon_left_msg.svg" />
                    <span>{{$t('front.mypage.msg')}} ({{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}})</span>
                  </a>
                </li>
                <li>
                  <a @click="goPageByNamez('qnaList')">
                      <img src="@/assets/img/left/icon_left_ask.svg" />
                      <span>{{$t('front.common.cscenter')}}</span>
                  </a>
                </li>
                <li>
                    <a @click="goPageByNamez('charge')">
                        <img src="@/assets/img/left/icon_left_in.svg" />
                        <span>{{$t('front.common.deposit')}}</span>
                    </a>
                </li>
                <li>
                    <a @click="goPageByNamez('exchange')">
                        <img src="@/assets/img/left/icon_left_out.svg" />
                        <span>{{$t('front.common.withdrawal')}}</span>
                    </a>
                </li>
                <li>
                  <a @click="goPageByNamez('betList')">
                      <img src="@/assets/img/left/icon_left_bet.svg" />
                      <span>{{$t('front.mypage.betList')}}</span>
                  </a>
                </li>
                <li>
                  <a @click="goPageByNamez('point')">
                      <img src="@/assets/img/left/icon_left_point.svg" />
                      <span>{{$t('front.common.point')}}</span>
                  </a>
                </li>
                <li>
                  <a @click="goPageByNamez('noticeList')">
                      <img src="@/assets/img/left/icon_left_notice.svg" />
                      <span>{{$t('front.common.notice')}}</span>
                  </a>
                </li>
                <li>
                    <a @click="goPageByNamez('info')">
                        <img src="@/assets/img/left/icon_left_info.svg" />
                        <span>{{$t('front.mypage.memInfoModify')}}</span>
                    </a>
                </li>
                <li>
                    <a @click="signOut()">
                        <img src="@/assets_mobile/img/icon/logout.svg" />
                        <span>{{$t('front.common.logout')}}</span>
                    </a>
                </li>
              </ul>
            </div>
          </div>
       </nav>
    </transition>
  </template>
</template>

<script>
import { mapState } from 'vuex'
import NewMessageMobile from '@/components/member/MessageMobile.vue'
import TMmodal from '@/components/common/TotalMoney.vue'

export default {
  name: 'Header',
  components: { TMmodal, NewMessageMobile },
  props: [
    'type'
  ],
  data () {
    return {
      isOpenLang: false,
      side: false,
      TMOpen: false
    }
  },
  computed: {
    ...mapState([
      'userData',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    onLogOut () {
      this.$root.signOut()
      this.$router.push({ name: 'main' })
      // location.reload()
    },
    goPageByNamez (name, params) {
      const query = { name: name }
      if (params) {
        query.params = params
      }
      this.$router.push(query)
      this.side = !this.side
    }
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
