<template>
  <main-header />
  <router-view :key="$route.fullPath"></router-view>
  <main-footer />
  <slot-list v-if="isSlotOpen" :groupCode="groupCode" :codeName="codeName" @isSlot="isSlotOpen = false"></slot-list>
  <loading></loading>
</template>

<script>

import MainHeader from './Header'
import MainFooter from './Footer'
import SlotList from '@/components/game/SlotList'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'MobileDefaultLayout',
  components: {
    SlotList,
    MainHeader,
    MainFooter,
    Loading
  },
  mounted () {
    this.emitter.on('isSlot', (data) => {
      if (data) {
        const groupCode = data.groupCode
        const codeName = data.codeName
        this.getSlotOpen(groupCode, codeName)
      } else {
        this.isSlotOpen = false
      }
    })
  },
  data () {
    return {
      isSlotOpen: false,
      groupCode: '',
      codeName: ''
    }
  },
  methods: {
    getSlotOpen (groupCode, codeName) {
      if (this.userData) {
        this.groupCode = groupCode
        this.codeName = codeName
        this.isSlotOpen = true
      } else {
        // this.onCheck('front.error.afterSignin')
        alert(this.$t('front.error.afterSignin'))
      }
    }
  }
}
</script>

<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.2s ease-in;
}

.fade-leave-active {
  transition: opacity 0.1s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(185px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-leave-to {
  transform: translateY(0px);
  opacity: 0;
}
</style>
