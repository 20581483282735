<template>
  <footer>
    <div class="wrapper">
      <div class="nav-bottom">
        <div class="footerInfo">
          <a class="flogo" @click="goPageByName('mobileMain')">
            <img src="../assets/img/site_logo.png">
          </a>
          <div class="copyright">Copyright ⓒ <em>Play Chasino</em> All Rights Reserved.</div>
          <div class="pay">
            <p>PAYMENT SUPPORTED BY</p>
            <img src="../assets/img/footer_card.png">
          </div>
          <ul class="sns" v-if="siteOption">
            <li @click="siteOption['sns1Link']">
              <img src="@/assets/img/telegram.svg" />
              <span><em>{{siteOption['sns1Id']}}</em></span>
            </li>
            <li @click="siteOption['sns2Link']">
              <img src="@/assets/img/kakaotalk1.svg" />
              <span><em>{{siteOption['sns2Id']}}</em></span>
            </li>
            <!-- <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
              <img src="@/assets/img/zalo.svg" />
              <span><em>{{siteOption['sns3Id']}}</em></span>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/footer.css"></style>
